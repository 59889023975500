









import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';

@Component({
  name: 'icon-trash-delete',
})
export default class IconTrashDelete extends Vue {
}
