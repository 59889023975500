


import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { TChatMessage } from '@/_modules/chat/types/chat-message.type';
import ChatMessage from '@/_modules/chat/components/chat-message/chat-message.vue';
import { Moment } from 'moment';

@Component({
  components: {
    ChatMessage,
  },
})
export default class ChatMessages extends Vue {

  @Prop({ type: Array, default: [] })
  public readonly messages: TChatMessage[];

  @Prop({ type: Boolean, default: false })
  public readonly isSpinnerVisible: boolean;

  @Prop({ type: Error, default: null })
  public readonly error: Error;

  @Prop({ type: String })
  public readonly groupId: string;

  public onScroll$: Subject<void> = new Subject<void>();

  private destroyed$: Subject<void> = new Subject<void>();
  private isFirstScrollPositionCheck: boolean = true;

  public created(): void {
    this.onScroll$.pipe(
      takeUntil(this.destroyed$),
      debounceTime(200),
    ).subscribe(() => {
      this.onScrollDebounced();
    });
  }

  public mounted(): void {
    this.$nextTick(() => {
      this.checkScrollPosition();
    });
  }

  public beforeDestroy(): void {
    this.onScroll$.complete();
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public onScroll(): void {
    this.onScroll$.next();
  }

  @Watch('messages', { immediate: true })
  private onMessagesChanged(): void {
    this.checkScrollPosition();
  }

  private onScrollDebounced(): void {
    if (!this.$refs.container) {
      return;
    }
    const container = (this.$refs.container as HTMLDivElement);
    const ratio = container.scrollTop / container.scrollHeight;
    if (ratio > 0.1) {
      return;
    }
    this.$emit('scroll-top-reached');
  }

  private checkScrollPosition(): void {
    if (!this.$refs.container) {
      return;
    }

    const container = (this.$refs.container as HTMLDivElement);

    if (this.isFirstScrollPositionCheck) {
      if (!this.messages || !this.messages.length) {
        return;
      }
      // this.$nextTick(() => {
      setTimeout(() => {
        container.scrollTo({
          top: container.scrollHeight,
        });
      }, 200);
      this.isFirstScrollPositionCheck = false;
      return;
    }

    const scrollFromBottomBefore = container.scrollHeight - container.scrollTop - container.offsetHeight;
    this.$nextTick(() => {
      const scrollFromBottomAfter = container.scrollHeight - container.scrollTop - container.offsetHeight;
      const scrollTo = container.scrollTop + scrollFromBottomAfter - scrollFromBottomBefore;
      container.scrollTo({
        top: scrollTo,
      });
    });
  }

  private getMessageClasses(message: TChatMessage, index: number): { [key: string]: boolean } {
    const isNextAuthorSame = this.isSameMessageAuthor(this.messages[index + 1], index + 1);
    return {
      'same-author-prev': this.isSameMessageAuthor(message, index),
      'same-author-next': isNextAuthorSame,
      'last-by-author': !isNextAuthorSame
    };
  }

  private isMessageDayDifferent(messageA: TChatMessage, messageB: TChatMessage): boolean {
    if (!messageA || !messageB) {
      return true;
    }
    const momentA: Moment = this.$moment(messageA.time);
    const momentB: Moment = this.$moment(messageB.time);
    return !momentA.isSame(momentB, 'day');
  }

  private isSameMessageAuthor(message: TChatMessage, index: number): boolean {
    const previousMessage = this.messages[index - 1];
    if (!message
      || !message.data
      || !message.data.contact_id
      || !previousMessage
      || !previousMessage.data
      || !previousMessage.data.contact_id
    ) {
      return false;
    }
    return message.data.contact_id === previousMessage.data.contact_id;
  }

  private async removeMessage(messageId: number): Promise<void> {
    this.$store.dispatch('chatStore/removeChatGroupTextMessage', {
      groupId: this.groupId,
      messageId: messageId,
    });
  }

}
