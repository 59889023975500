var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chat-message-composer"},[_c('div',{staticClass:"column column-input"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.newMessageText),expression:"newMessageText"}],ref:"textarea",staticClass:"textarea",attrs:{"disabled":_vm.isSendingMessage || _vm.disabled},domProps:{"value":(_vm.newMessageText)},on:{"keypress":_vm.onTextareaKeypress,"input":function($event){if($event.target.composing){ return; }_vm.newMessageText=$event.target.value}}}),(_vm.isSendingThrottled && _vm.isSendingThrottleVisible)?_c('div',{staticClass:"wait-message"},[_c('span',{staticClass:"progress",style:(_vm.sendingThrottleProgressStyle)}),_vm._v(" "+_vm._s(_vm.$t('eventChat.throttledMessage', { remaining: _vm.sendingThrottleRemainingSeconds }))+" ")]):_vm._e(),(_vm.isMessageOverLengthLimit)?_c('div',{staticClass:"wait-message wait-message-length-error"},[_vm._v(" "+_vm._s(_vm.$t('errors.validation.directMessageIsTooLong'))+" ")]):_vm._e()]),_c('div',{staticClass:"column column-actions"},[_c('button',{staticClass:"button button-toggle-emoji-picker"},[_c('emoji-picker',{attrs:{"search":_vm.emojiSearchTerm},on:{"emoji":_vm.onEmojiChoice},scopedSlots:_vm._u([{key:"emoji-invoker",fn:function(ref){
var clickEvent = ref.events.click;
return _c('button',{staticClass:"emoji-invoker",on:{"click":function($event){$event.stopPropagation();return clickEvent($event)}}},[_c('svg',{staticClass:"h-6 w-6 fill-current text-grey",attrs:{"viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M0 0h24v24H0z","fill":"none"}}),_c('path',{attrs:{"d":"M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z"}})])])}},{key:"emoji-picker",fn:function(ref){
var emojis = ref.emojis;
var insert = ref.insert;
var display = ref.display;
return _c('div',{},[_c('div',{staticClass:"emoji-picker",style:({
              bottom: '160%',
              right: '0',
              width: _vm.emojiPickerWidth,
              marginRight: '-20px',
            })},[_c('div',{staticClass:"emoji-picker__search"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.emojiSearchTerm),expression:"emojiSearchTerm"},{name:"focus",rawName:"v-focus"}],domProps:{"value":(_vm.emojiSearchTerm)},on:{"input":function($event){if($event.target.composing){ return; }_vm.emojiSearchTerm=$event.target.value}}}),_c('span',{staticClass:"emoji-picker__close",on:{"click":function($event){display.visible = false}}})]),_c('div',_vm._l((emojis),function(emojiGroup,category){return _c('div',{key:category},[_c('h5',[_vm._v(_vm._s(category))]),_c('div',{staticClass:"emojis"},_vm._l((emojiGroup),function(emoji,emojiName){return _c('span',{key:emojiName,attrs:{"title":emojiName},on:{"click":function($event){return insert(emoji)}}},[_vm._v(" "+_vm._s(emoji)+" ")])}),0)])}),0)])])}}])})],1),_c('button',{staticClass:"button button-message-send",class:{ disabled: _vm.isSendingMessage || _vm.disabled || _vm.isMessageOverLengthLimit },on:{"click":_vm.onButtonMessageSendClick}},[_c('icon-message-send')],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }